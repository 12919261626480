import { useCallbackRef } from '@playful/utils';
import { useEffect, useRef, useState } from 'react';
export function useIntersectionObserver(callback, options) {
  var cb = useCallbackRef(callback);
  var _useState = useState(null),
    observer = _useState[0],
    setObserver = _useState[1];
  var observerRef = useRef(null);
  useEffect(function () {
    if (observerRef.current) return;

    // don't create a new observer unless we destroyed the old one already
    observerRef.current = new IntersectionObserver(cb, options);
    setObserver(observerRef.current);
    return function () {
      var _observerRef$current;
      (_observerRef$current = observerRef.current) === null || _observerRef$current === void 0 || _observerRef$current.disconnect();
      observerRef.current = null;
    };
  }, [cb, options]);
  return observer;
}
export function useIntersectionObserved(observer, el) {
  useEffect(function () {
    if (!observer || !el) return;
    observer.observe(el);
    return function () {
      observer.unobserve(el);
    };
  }, [observer, el]);
}