import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var _excluded = ["projectId", "showPublished", "previewTransform"];
var __jsx = React.createElement;
import { Image } from '@chakra-ui/react';
import { getResourceUrl } from '@playful/runtime';
import { PREVIEW } from '../workbench/project/resources';
import React from 'react';
import logoUrl from '../assets/black.svg?url';
import { useProject } from '../hooks/useProject';
import { useUserContext } from '../user/UserContext';
export var useProjectPreview = function useProjectPreview(_ref) {
  var projectInfo = _ref.projectInfo,
    _ref$showPublished = _ref.showPublished,
    showPublished = _ref$showPublished === void 0 ? false : _ref$showPublished,
    _ref$previewTransform = _ref.previewTransform,
    previewTransform = _ref$previewTransform === void 0 ? 'rs:fit:640:640' : _ref$previewTransform;
  var projectVersion = projectInfo === null || projectInfo === void 0 ? void 0 : projectInfo.version;
  var _useUserContext = useUserContext(),
    authToken = _useUserContext.authToken;
  var resourceId = showPublished ? projectInfo === null || projectInfo === void 0 ? void 0 : projectInfo.publishedProject : projectInfo === null || projectInfo === void 0 ? void 0 : projectInfo.project;
  var opts = {
    transform: previewTransform,
    v: '' + projectVersion
  };
  if (!showPublished) {
    // if we're showing a private project to the project's owner attach their auth token.
    // add it this was to avoid `auth=undefined` on public requests
    opts['auth'] = authToken;
  }
  var previewUrl = resourceId && getResourceUrl(resourceId, PREVIEW, '', opts);
  return {
    previewUrl: previewUrl ? previewUrl : undefined
  };
};
export function PreviewImage(props) {
  return __jsx(Image, _extends({
    display: "block",
    width: "full",
    height: "full",
    objectFit: 'cover',
    objectPosition: 'top',
    fallbackSrc: logoUrl,
    fallbackStrategy: 'onError'
  }, props, {
    src: !!props.src ? props.src : logoUrl
  }));
}
export function ProjectPreview(_ref2) {
  var projectId = _ref2.projectId,
    _ref2$showPublished = _ref2.showPublished,
    showPublished = _ref2$showPublished === void 0 ? false : _ref2$showPublished,
    _ref2$previewTransfor = _ref2.previewTransform,
    previewTransform = _ref2$previewTransfor === void 0 ? 'rs:fit:640:640' : _ref2$previewTransfor,
    props = _objectWithoutProperties(_ref2, _excluded);
  var _useProject = useProject({
      id: projectId
    }),
    projectInfo = _useProject.projectInfo;
  var _useProjectPreview = useProjectPreview({
      projectInfo: projectInfo,
      showPublished: showPublished,
      previewTransform: previewTransform
    }),
    previewUrl = _useProjectPreview.previewUrl;
  var title = projectInfo === null || projectInfo === void 0 ? void 0 : projectInfo.title;
  return __jsx(PreviewImage, _extends({
    key: previewUrl,
    src: previewUrl,
    alt: title
  }, props));
}