import { jsx as _jsx } from "react/jsx-runtime";
import { Grid, theme } from '@chakra-ui/react';
/**
 *
 * A responsive, flexible grid based on:
 * * a max amount of cols per row
 * * the min width of the cols
 * * the gap in the grid
 * when resizing the viewport, it will attempt to fit as many cols per row up to, but not
 * exceeding, the max number of cols provided, and without shrinking the col widths below the
 * min col width provided.
 */
export function FlexGrid({ gap = 3, minColW = 4, maxCols = 4, sx, style, ...props }) {
    return (_jsx(Grid, { display: 'grid', sx: {
            gap: 'var(--cgrid-layout-gap)',
            ...sx,
        }, style: {
            '--cgrid-layout-gap': theme.space[gap],
            '--cgrid-max-cols': maxCols.toString(),
            '--cgrid-total-gap-w': 'calc(var(--cgrid-max-cols) * var(--cgrid-layout-gap))',
            '--cgrid-item--maxW': 'calc((100% - var(--cgrid-total-gap-w)) / var(--cgrid-max-cols))',
            gridTemplateColumns: `repeat(auto-fill, minmax(max(${minColW}, var(--cgrid-item--maxW)), 1fr))`,
            ...style,
        }, ...props }));
}
